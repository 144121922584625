export enum TagTypeEnumerator {
  THEMATIC_BASE = 1,
  KNOWLEDGE_AREA = 2,
}

export const tagTypeEnumeratorMap:
    Map<TagTypeEnumerator, string> = new Map<TagTypeEnumerator, string>([
      [TagTypeEnumerator.THEMATIC_BASE, 'Eixo temático'],
      [TagTypeEnumerator.KNOWLEDGE_AREA, 'Área do conhecimento'],
]);

export const tagTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(tagTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
