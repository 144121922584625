export enum ClassYearEnumerator {

  // -=-=-= Infantil -=-=-=-
  THREEYEARS= 3,
  FOURYEARS= 4,
  FIVEYEARS= 5,

  // -=-=-= Ensino médio -=-=-=-
  FIRSTGRADE= 6,
  SECONDGRADE= 7,
  THIRDGRADE= 8,

  // -=-=-= Ensino fundamental 1 -=-=-=-
  FIRSTYEAR = 15,
  SECONDYEAR = 16,
  THIRDYEAR = 17,
  FOURTHYEAR = 18,
  FIFTHYEAR = 19,

  // -=-=-= Ensino fundamental 2 -=-=-=-
  SIXTHYEAR= 11,
  SEVENTHYEAR= 12,
  EIGHTHYEAR= 13,
  NINTHYEAR= 14,
}

export const classYearEnumeratorMap: Map<ClassYearEnumerator, string> = new Map<ClassYearEnumerator, string>([
  [ClassYearEnumerator.THREEYEARS, '3 anos'],
  [ClassYearEnumerator.FOURYEARS, '4 anos'],
  [ClassYearEnumerator.FIVEYEARS, '5 anos'],
  [ClassYearEnumerator.FIRSTYEAR, '1º ano'],
  [ClassYearEnumerator.SECONDYEAR, '2º ano'],
  [ClassYearEnumerator.THIRDYEAR, '3º ano'],
  [ClassYearEnumerator.FOURTHYEAR, '4º ano'],
  [ClassYearEnumerator.FIFTHYEAR, '5º ano'],
  [ClassYearEnumerator.SIXTHYEAR, '6º ano'],
  [ClassYearEnumerator.SEVENTHYEAR, '7º ano'],
  [ClassYearEnumerator.EIGHTHYEAR, '8º ano'],
  [ClassYearEnumerator.NINTHYEAR, '9º ano'],
  [ClassYearEnumerator.FIRSTGRADE, '1ª série'],
  [ClassYearEnumerator.SECONDGRADE, '2ª série'],
  [ClassYearEnumerator.THIRDGRADE, '3ª série'],
]);

export const classYearProjetEnumeratorMap: Map<ClassYearEnumerator, string> = new Map<ClassYearEnumerator, string>([
  [ClassYearEnumerator.FIRSTGRADE, '1ª série'],
  [ClassYearEnumerator.SECONDGRADE, '2ª série'],
  [ClassYearEnumerator.THIRDGRADE, '3ª série'],
]);

export const classYearIeaEnumeratorMap: Map<ClassYearEnumerator, string> = new Map<ClassYearEnumerator, string>([
  [ClassYearEnumerator.THREEYEARS, '3 anos'],
  [ClassYearEnumerator.FOURYEARS, '4 anos'],
  [ClassYearEnumerator.FIVEYEARS, '5 anos'],
  [ClassYearEnumerator.FIRSTYEAR, '1º ano'],
  [ClassYearEnumerator.SECONDYEAR, '2º ano'],
  [ClassYearEnumerator.THIRDYEAR, '3º ano'],
  [ClassYearEnumerator.FOURTHYEAR, '4º ano'],
  [ClassYearEnumerator.FIFTHYEAR, '5º ano'],
  [ClassYearEnumerator.SIXTHYEAR, '6º ano'],
  [ClassYearEnumerator.SEVENTHYEAR, '7º ano'],
  [ClassYearEnumerator.EIGHTHYEAR, '8º ano'],
  [ClassYearEnumerator.NINTHYEAR, '9º ano'],
]);

export const classYearEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(classYearEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));

export const classYearProjetEnumeratorList: Array<{ id: number, description: string }> =
    Array.from(classYearProjetEnumeratorMap)
      .map((o: Array<any>) => ({
        id: o[0],
        description: o[1],
      }));

export const classYearIeaEnumeratorList: Array<{ id: number, description: string }> =
    Array.from(classYearIeaEnumeratorMap)
      .map((o: Array<any>) => ({
        id: o[0],
        description: o[1],
      }));

export const isInfantil: any = (value: any): any => [3, 4, 5].includes(value);

export const isPrimary: any = (value: any): any => [15, 16, 17, 18, 19].includes(value);

export const isSecondary: any = (value: any): any => [11, 12, 13, 14].includes(value);

export const isHighSchool: any = (value: any): any => [6, 7, 8].includes(value);
