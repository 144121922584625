import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { ActivityQuestion, ActivityQuestionSearchParameter } from '../models/activity-question.model';
import { Pagination } from '../models';

@Injectable()
export class ActivityQuestionService {
  public basePath: string = '/activity-question';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(activityQuestion: ActivityQuestion): Promise<ActivityQuestion> {
      return this._apiService
      .post(`${this.basePath}`, activityQuestion).toPromise();
  }

  public getById(id: string, params?: any): Promise<ActivityQuestion> {
    return this._apiService
        .get(`${this.basePath}/${id}`, params).toPromise();
  }

  async update(id: any, activityQuestion: ActivityQuestion): Promise<ActivityQuestion> {
    if (activityQuestion) {
      activityQuestion = await this._apiService.put(`${this.basePath}/${id}`, activityQuestion).toPromise();
    }
    return activityQuestion;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
