import { BaseModel } from './base.model';

export class CertificateSettings extends BaseModel {
  id?: string;
  schoolId?: string;
  schoolName?: number;
  logoUrl?: string;

  directorName?: string;
  directorSignature?: string;

  percContentViewed?: number;
  percActivitiesDone?: number;
  percTestGrade?: number;
  releaseType?: number;
  courseType?: number;
}