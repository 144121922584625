import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Material, Pagination, MaterialSearchParameter } from '../models/index';

@Injectable()
export class MaterialService {
  public basePath: string = '/study-material';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(material: Material): Promise<Material> {
    return this._apiService
      .post(`${this.basePath}`, material).toPromise();
  }

  // R
  public getRootFolder(searchParameter: MaterialSearchParameter = null): Promise<{materials: Pagination<Material>}> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  // R
  public getFolderMaterial(id: string, searchParameter: MaterialSearchParameter = null)
    : Promise<{Material: Material, materials: Pagination<Material>}> {
    return this._apiService
      .get(`${this.basePath}/${id}/folder`, sanitizeGet(searchParameter)).toPromise();
  }

  // R
  public getBreadcrumbMaterial(id: string): Promise<any> {
    return this._apiService
      .get(`${this.basePath}/${id}/breadcrumb`).toPromise();
  }

  // R by ID
  public getById(id: string): Promise<Material> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  // U
  async update(id: any, material: Material): Promise<Material> {
    if (material) {
      material = await this._apiService.put(`${this.basePath}/${id}`, material).toPromise();
    }
    return material;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
