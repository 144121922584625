import { BaseModel, SearchParameter } from './base.model';
import { Module } from './module.model';
import { School } from './school.model';
import { Tag } from './tag.model';
import { CourseTest } from './course-test.model';

export class Course extends BaseModel {
    title?: string;
    subtitle?: string;
    type?: number;
    description?: string;
    closureTaskDescription?: string;
    photoUrl?: string;
    videoUrl?: string;
    program?: string;
    modules?: Array<Module>;
    schools?: Array<School>;
    tags?: Array<Tag>;
    tagIdList?: Array<string>;
    test?: CourseTest;
}

export class CourseSearchParameter extends SearchParameter {
    title?: string;
    type?: number;
    selectives?: boolean;
    schoolId?: string;
  }