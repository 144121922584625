import { ProfileTypeEnumerator } from 'app/core';

export const accessControlList: any = {
  canView: [
    ProfileTypeEnumerator.ADMIN,
    ProfileTypeEnumerator.COORDINATOR,
    ProfileTypeEnumerator.TEACHER,
    ProfileTypeEnumerator.ADVISER,
    ProfileTypeEnumerator.ATTENDANT,
  ],
};
