import { BaseModel, SearchParameter } from "./base.model";
import { ProfileTypeEnumerator } from "../enumerators/profile-type.enumerator";
import { School, StudentClass } from "app/core";
import { Teacher } from "./teacher.model";

export class User extends BaseModel {
  name?: string;
  email?: string;
  password?: string;
  profileType?: ProfileTypeEnumerator;
  changePasswordToken?: string;
  changePasswordTokenExpiresAt?: Date | string;
  lastAccess?: Date | string;
  status?: string;
  classId?: string;
  schoolId?: string;
  academicRecord?: string;
  isSaePartnership?: boolean;
  isSasPartnership?: boolean;
  school?: School;
  studentClass?: StudentClass;
  teacherStudentClasses?: Teacher["teacherStudentClasses"];
  quizAnswers?: Array<any>;
  profilePictureUrl?: string;
  sasId?: string;
  edoolsId?: string;
  schools?: Array<School>;
}

export class UserSelector {
  id: number;
  name: string;
}

export class UserSearchParameter extends SearchParameter {
  name?: string;
  email?: string;
  schoolId?: string;
  classId?: string;
  studentClassIdList?: Array<string>;
  schoolIdList?: Array<string>;
  custom?: string;
  profileType?: any;
  profileTypeList?: any;
  segment?: number;
  collection?: number;
  year?: number;
  cellAuthorizationCode?: boolean;
  lastAccess?: any;
  classIdList?: any;
  projet?: boolean;
  notInTeacherPrivateChat?: boolean;
  notInCourseId?: string;
  notInVirtualGroupId?: string;
  yearList?: any;
}
