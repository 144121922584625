import { Environment } from './environment.model';

let apiUrl: string;
let apiUrlNew: string;
let reactUrl: string;
let portalUrl: string;

const host: any = window.location.hostname;

switch (host) {
  case 'dashboard-2022dev.programapleno.com.br':
    apiUrl = "https://api-2022-legacy-dev.arco-nse.com";
    apiUrlNew = 'https://api-dev.arco-nse.com/v1';
    reactUrl = `https://dashboarddev.programapleno.com.br`;
    portalUrl = `https://portaldev.programapleno.com.br`;
    break;
  case 'dashboard-2022hmg.programapleno.com.br':
    apiUrl = "https://api-2022-legacy-dev.arco-nse.com";
    apiUrlNew = 'https://api-hmg.arco-nse.com/v1';
    reactUrl = `https://dashboardhmg.programapleno.com.br`;
    portalUrl = `https://portalhmg.programapleno.com.br`;
    break;
  case 'dashboard-2022.programapleno.com.br':
    apiUrl = "https://api-prod-legacy.arco-nse.com";
    apiUrlNew = 'https://api-prod.arco-nse.com/v1';
    reactUrl = `https://dashboard.programapleno.com.br`;
    portalUrl = `https://portal.programapleno.com.br`;
    break;
  default:
    apiUrl = "https://api-prod-legacy.arco-nse.com";
    apiUrlNew = 'https://api-prod.arco-nse.com/v1';
    reactUrl = "http://localhost:3535";
    portalUrl = "http://localhost:7080";
    break;
}

export const environment: Environment = {
  production: false,
  apiUrl,
  apiUrlNew,
  reactUrl,
  portalUrl,
};
