import { BaseModel, SearchParameter } from './base.model';
import { User } from './user.model';

export class UserMission extends BaseModel {
    id: string;
    userId: string;
    missionId: string;
    isDone: boolean;
    inProgress: boolean;
    isAttendance: boolean | null;
    currentTaskId: string;
    user: User;
}

export class UserMissionSearchParameter extends SearchParameter {
    missionIdList?: Array<string>;
    studentClassIdList?: Array<string>;
    updatedAt?: any;
    status?: any;
    groupByUpdatedDay?: boolean;
    missionTypeList?: Array<number>;
    missionType?: number;
    schoolIdList?: Array<string>;
}
