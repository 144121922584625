export enum ProductTypeEnumerator {
  IEA = 1,
  PROJET = 2,
  ELECTIVE = 3,
  TRAIL = 4,
  EDOOLS = 5,
}

export enum ProductTypePermissionEnumerator {
  IEA = 101,
  PROJET = 102,
  ELECTIVE = 103,
  TRAIL = 104,
  EDOOLS = 105,
}

export const productTypeEnumeratorMap:
  Map<ProductTypeEnumerator, string> = new Map<ProductTypeEnumerator, string>([
    [ProductTypeEnumerator.IEA, 'Inovadores em ação'],
    [ProductTypeEnumerator.PROJET, 'Projet'],
    [ProductTypeEnumerator.ELECTIVE, 'Eletivas'],
    [ProductTypeEnumerator.TRAIL, 'TECER'],
    [ProductTypeEnumerator.EDOOLS, 'Pleno Digital'],
]);

export const productTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(productTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));