export enum LmsContentTypeEnumerator {
  TEXTAREA = 1,
  HTML = 2,
  PDF = 3,
}

export const lmsContentTypeEnumeratorMap:
    Map<LmsContentTypeEnumerator, string> = new Map<LmsContentTypeEnumerator, string>([
      [LmsContentTypeEnumerator.TEXTAREA, 'Campo de texto'],
      [LmsContentTypeEnumerator.HTML, 'HTML'],
      [LmsContentTypeEnumerator.PDF, 'PDF'],
]);

export const lmsContentTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(lmsContentTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
