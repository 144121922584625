import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { SchoolCourse, SchoolCourseSearchParameter } from '../models/school-course.model';
import { Pagination } from '../models';

@Injectable()
export class SchoolCourseService {
  public basePath: string = '/school-course';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(schoolCourse: any): Promise<any> {
    return this._apiService
      .post(`${this.basePath}`, schoolCourse).toPromise();
  }

  public updateById(id: string, schoolCourse: any): Promise<any> {
    return this._apiService
      .put(`${this.basePath}/${id}`, schoolCourse).toPromise();
  }

  public getById(id: string): Promise<any> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getAvailableCourses(searchParameter: SchoolCourseSearchParameter = null): Promise<Pagination<any>> {
    return this._apiService
      .get(`${this.basePath}/available-courses`, sanitizeGet(searchParameter)).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
