import { BaseModel, SearchParameter } from './base.model';
import { ClassYearEnumerator, ClassCollectionEnumerator, ClassSegmentEnumerator } from '../enumerators';

export class StudentClass extends BaseModel {
  name: string;
  segment: ClassSegmentEnumerator;
  collection: ClassCollectionEnumerator;
  year: ClassYearEnumerator;
  activeOds?: string;
  schoolId: string;
  usersCapacity: number;
  usersAmount: number;
  familyCapacity: number;
  familyAmount: number;
  school: {
    id: string,
    name: string,
  };
  ODS?: Array<
    {
      id: string,
      number: number,
      title: string,
      studentClassODS: {
        id: string,
        isActive: boolean,
      },
    }
  >;
  odsList?: Array<{
    odsId?: string,
    isActive?: boolean,
  }>;
  projet: boolean;
}

export class StudentClassSelector {
  id: number;
  name: string;
}

export class StudentClassSearchParameter extends SearchParameter {
  name?: string;
  idSchool?: string;
  odsId?: string;
  segment?: number;
  collection?: number;
  year?: number;
  search?: string;
  projet?: boolean;
  idList?: Array<string>;
  profileTypeList?: any;
  schoolListSearch?: any;
}
