import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../services';
import { catchError } from 'rxjs/operators';

enum keys {
  Authorization = 'Authorization',
}

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {};

    // TODO
    if (req.url.includes('import-student-csv') || req.url.includes('upload-image') || req.url.includes('bulk-delete-users')) {
      headersConfig = {
        Accept: 'application/json',
        'arco-brand-domain': 'programapleno.com.br',
      };
    } else {
      headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'arco-brand-domain': 'programapleno.com.br',

        // -=-=-=- Disable Caches -=-=-=-=-
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 0,
      };
    }

    const token: string = this.authService.getToken();

    if (token) {
      headersConfig[keys.Authorization] = `Bearer ${token}`;
    }

    const request: HttpRequest<any> = req.clone({setHeaders: headersConfig});
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.destroyUser();
        }
        return throwError(error);
      }),
    );
  }
}
