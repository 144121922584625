import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User, Pagination, UserSearchParameter } from '../models/index';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { Teacher } from '../models/teacher.model';
import { environment } from 'env/environment';

@Injectable()
export class UserService {
  public basePath: string = '/user';
  public authPath: string = '/auth';

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
  ) {
  }

  public authenticate(email: string, password: string, profileType: number): Promise<User> {
    return this._apiService
      .post(`${this.authPath}`, {email, password}, environment.apiUrlNew)
      .pipe(map(
        (response: any) => {
          this._authService.setToken(response.token);
          this._authService.setRefreshToken(response.refreshToken);
          return response;
        },
      )).toPromise();
  }

  public authenticateBySchool(credentialsOptions: any ): Promise<User> {
    return this._apiService
      .post(`${this.authPath}/dashboard/school`, credentialsOptions)
      .pipe(map(
        (response: any) => {
          this._authService.setToken(response.token);
          this._authService.setRefreshToken(response.refreshToken);
          return response;
        },
      )).toPromise();
  }

  public create(user: User): Promise<User> {
    return this._apiService
      .post(`${this.basePath}`, user).toPromise();
  }

  public createStudent(user: User): Promise<User> {
    return this._apiService
      .post(`${this.basePath}/student`, user).toPromise();
  }

  async getLoggedUser(): Promise<User> {
    let user: User = this._authService.getUser();
    if (!user) {
      user = await this._apiService.get(`${this.basePath}/me`).toPromise();
      this._authService.setUser(user);
    }
    return user;
  }

  async getLoggedUserForce(): Promise<User> {
    const user: User = await this._apiService.get(`${this.basePath}/me`).toPromise();
    this._authService.setUser(user);
    return user;
  }

  async updateUser(id: any, user: User): Promise<User> {
    if (user) {
      user = await this._apiService.put(`${this.basePath}/${id}`, user).toPromise();
    }
    return user;
  }

  async uploadPhoto(data: any): Promise<User> {
    const user: User = await this._apiService.post(`${this.basePath}/selfie`, data).toPromise();
    return user;
  }

  async updateStudent(id: any, user: User): Promise<User> {
    if (user) {
      user = await this._apiService.put(`${this.basePath}/student/${id}`, user).toPromise();
    }
    return user;
  }

  async refreshLoggedUser(): Promise<any> {
    const user: any = await this._apiService.get(`${this.basePath}/auth/refresh`).toPromise();
    this._authService.setUser(user);
    this._authService.setToken(user.token);
    this._authService.setRefreshToken(user.refreshToken);
    return user;
  }

  public getAuthorization(): string {
    return `Bearer ${this._authService.getToken()}`;
  }

  public destroyLoggedUser(): void {
    this._authService.destroyUser();
  }

  public sendRecoveryEmail(email: string): Observable<User> {
    return this._apiService
      .get(`${this.basePath}/passwordRecoverySendEmail`, {email});
  }

  public getAll(searchParameter: UserSearchParameter = null): Promise<Pagination<User>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getAllStudent(searchParameter: UserSearchParameter = null): Promise<Pagination<User>> {
    return this._apiService
      .get(`${this.basePath}/students`, sanitizeGet(searchParameter)).toPromise();
  }

  public exportUsers(searchParameter: UserSearchParameter = null): any {
    return this._apiService.get(`${this.basePath}/export`, sanitizeGet(searchParameter)).toPromise();
  }

  public updateById(id: string, user: User): Promise<User> {
    return this._apiService
      .put(`${this.basePath}/${id}`, user).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public removeByIdList(idList: Array<string>): Promise<void> {
    return this._apiService
      .deleteList(`${this.basePath}`, idList).toPromise();
  }

  public sendRecovery(email: string): Promise<void> {
    return this._apiService
      .put(`${this.basePath}/password-recovery`, {email}).toPromise();
  }

  public changeRecoveryPassword(data: {
    changePasswordToken: string,
    password: string,
    email: string,
  }): Promise<void> {

    return this._apiService
      .put(`${this.basePath}/change-recovered-token`, data).toPromise();
  }

  public getById(id: string): Promise<User> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getByIdWithQuizCompetence(id: string): Promise<User> {
    return this._apiService
      .get(`${this.basePath}/${id}/quiz-competence`).toPromise();
  }

  public getTeacherById(id: string): Promise<Teacher> {
    return this._apiService
      .get(`${this.basePath}/${id}/teacher`).toPromise();
  }

  public importStudentCsv(classId: string, file: FormData): Promise<Array<User>> {
    return this._apiService
      .postFormData(`${this.basePath}/import-student-csv?classId=${classId}`, file).toPromise();
  }

  public importStudentCsvBulk(file: FormData): Promise<Array<User>> {
    return this._apiService
      .postFormData(`${this.basePath}/import-student-csv-bulk`, file).toPromise();
  }

  public bulkDeleteUsers(file: FormData): Promise<Array<User>> {
    return this._apiService
      .postFormData(`${this.basePath}/bulk-delete-users`, file).toPromise();
  }

  public getAllWithQuizCompetence(searchParameter: UserSearchParameter = null): Promise<Pagination<User>> {
    return this._apiService
      .get(`${this.basePath}/quiz-competences`, sanitizeGet(searchParameter)).toPromise();
  }

  public getDashboardHome(searchParameter: UserSearchParameter = null): Promise<any> {
    return this._apiService
      .get(`${this.basePath}/dashboard-home`, sanitizeGet(searchParameter)).toPromise();
  }
}
