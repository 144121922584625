import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { StudentClass, StudentClassSearchParameter } from '../models/student-class.model';
import { Pagination } from '../models';
import { environment } from 'env/environment';

@Injectable()
export class StudentClassService {
  public basePath: string = '/student-class';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public getSelectives(): Promise<StudentClass> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  public getSelectivesToNotification(searchParameter: any = name)
    : Promise<Array<StudentClass>> {
    return this._apiService
      .get(`${this.basePath}/selectives-notification`, sanitizeGet(searchParameter)).toPromise();
  }

  public getAll(searchParameter: StudentClassSearchParameter = null): Promise<Pagination<StudentClass>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter), environment.apiUrlNew).toPromise();
  }

  public getById(id: string): Promise<StudentClass> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public updateById(id: string, data: StudentClass): Promise<StudentClass> {
    return this._apiService
      .put(`${this.basePath}/${id}`, data).toPromise();
  }

  public create(data: StudentClass): Promise<StudentClass> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public migrateStudent(
    cuurrentStudentClassId: string,
    userIdList: Array<string>,
    nextStudentClassId: string ): Promise<void> {
    return this._apiService
      .put(`${this.basePath}/${cuurrentStudentClassId}/migrate-students`,
      {userIdList, nextStudentClassId},
      ).toPromise();
  }
}
