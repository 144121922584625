export enum ClassSegmentEnumerator {
  INFANTIL = 1,
  ANOS_INICIAIS = 2,
  ANOS_FINAIS = 3,
  ENSINOMEDIO = 4,
}

export const classSegmentEnumeratorMap: Map<ClassSegmentEnumerator, string> = new Map<ClassSegmentEnumerator, string>([
  [ClassSegmentEnumerator.INFANTIL, 'Infantil'],
  [ClassSegmentEnumerator.ANOS_INICIAIS, 'Anos iniciais'],
  [ClassSegmentEnumerator.ANOS_FINAIS, 'Anos finais'],
  [ClassSegmentEnumerator.ENSINOMEDIO, 'Ensino Médio'],
]);

export const classSegmentEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(classSegmentEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
