import { BaseModel } from './base.model';

export class Address extends BaseModel {
  userId: number;
  country: string;
  zipCode: string;
  city: string;
  state: string;
  lineOne: string;
  lineTwo?: string;
  alias?: string;
  lat?: string;
  lng?: string;
  observation?: string;
  formattedAddress?: string;

  bindLabel?: string;
  isExpanded?: boolean;
}
