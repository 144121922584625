import { BaseModel, SearchParameter } from './base.model';

export class CourseTest extends BaseModel {
    id?: string;
    title?: string;
    description?: string;
    questions?: Array<any>;
}

export class CourseTestSearchParameter extends SearchParameter {
    title?: string;
    description?: string;
  }