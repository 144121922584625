import { BaseModel, SearchParameter } from './base.model';
import { Activity } from './activity.model';

export class Content extends BaseModel {
    id?: string;
    code?: string;
    type?: number;
    title?: string;
    description?: string;
    fileUrl?: string;
    file?: any;
    activities?: Array<Activity>;
}

export class ContentSearchParameter extends SearchParameter {
    title?: string;
    code?: string;
    type?: number;
    idList?: Array<string>;
    notInIdList?: Array<string>;
  }